<template>
  <custom-table
    :columns="columns"
    :view-modes="viewModes"
    :default-view-mode="viewMode"
    :custom-view="customView"
    api-url="/material-receipt-history"
    api-download-url="/material-receipt-history-export"
    download-file-name="nhap-kho-npl.xlsx"
  ></custom-table>
</template>

<script>
import {
  RECEIPT_DETAIL_STATUS_OPTIONS,
  RECEIPT_STATUS_OPTIONS,
  RECEIPT_VIEW_MODES,
  CUSTOM_VIEW_MATERIAL_WAREHOUSE_RECEIPT,
} from "@/libs/const";

export default {
  name: "History",
  components: {},
  data: () => ({
    viewMode: "view0",
    viewModes: [...RECEIPT_VIEW_MODES],
    customView: CUSTOM_VIEW_MATERIAL_WAREHOUSE_RECEIPT,
    detailStatusOptions: [...RECEIPT_DETAIL_STATUS_OPTIONS],
    statusOptions: [...RECEIPT_STATUS_OPTIONS],
    columns: [],
  }),
  created() {
    const columns = [
      {
        type: "select-material-warehouse",
        label: this.$t("labels.warehouse_1"),
        placeholder: this.$t("labels.warehouse_1"),
        name: "id_material_warehouse",
        hasSort: false,
        sortName: "material_warehouse_code",
        key: "material_warehouse_code",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.created_at"),
        placeholder: this.$t("labels.created_at"),
        name: "created_at",
        hasSort: true,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.session_code"),
        placeholder: this.$t("labels.session_code"),
        name: "material_receipt_tracking",
        hasSort: true,
        sortName: "material_receipt_tracking",
        key: "material_receipt_tracking",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: true,
        sortName: "tracking_id",
        key: "tracking_id",
        showModes: ["view1"],
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.receipt_time"),
        placeholder: this.$t("labels.receipt_time"),
        name: "receipted_at",
        hasSort: true,
        sortName: "receipted_at",
        key: "receipted_at",
        showModes: ["view1"],
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status_1"),
        placeholder: this.$t("labels.status_1"),
        name: "status",
        hasSort: true,
        sortName: "status",
        key: "status_name",
        options: this.statusOptions,
        showModes: ["view0"],
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status_1"),
        placeholder: this.$t("labels.status_1"),
        name: "detail_status",
        hasSort: true,
        sortName: "detail_status",
        key: "detail_status_txt",
        options: this.detailStatusOptions,
        showModes: ["view1"],
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.first_receipt"),
        placeholder: this.$t("labels.first_receipt"),
        name: "first_handle_time",
        hasSort: true,
        sortName: "first_handle_time",
        key: "first_handle_time",
        showModes: ["view0"],
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.last_receipt"),
        placeholder: this.$t("labels.last_receipt"),
        name: "last_handle_time",
        hasSort: true,
        sortName: "last_handle_time",
        key: "last_handle_time",
        showModes: ["view0"],
      },
      {
        type: "input-filter",
        label: this.$t("labels.barcode"),
        placeholder: this.$t("labels.barcode"),
        name: "code",
        hasSort: true,
        sortName: "code",
        key: "code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_name"),
        placeholder: this.$t("labels.goods_name"),
        name: "material_name",
        hasSort: true,
        sortName: "material_name",
        key: "material_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.goods_description"),
        placeholder: this.$t("labels.goods_description"),
        name: "material_description",
        hasSort: true,
        sortName: "material_description",
        key: "material_description",
      },
      {
        type: "input-filter",
        label: this.$t("labels.size"),
        placeholder: this.$t("labels.size"),
        name: "material_size",
        hasSort: true,
        sortName: "material_size",
        key: "material_size",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.request_quantity"),
        placeholder: this.$t("labels.request_quantity"),
        name: "request_quantity",
        hasSort: true,
        sortName: "request_quantity",
        key: "request_quantity",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipt_quantity"),
        placeholder: this.$t("labels.receipt_quantity"),
        name: "receipted_quantity",
        hasSort: true,
        sortName: "receipted_quantity",
        key: "receipted_quantity",
        showModes: ["view0"],
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.receipt_quantity"),
        placeholder: this.$t("labels.receipt_quantity"),
        name: "history_quantity",
        hasSort: true,
        sortName: "history_quantity",
        key: "history_quantity",
        showModes: ["view1"],
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.sub_quantity"),
        placeholder: this.$t("labels.sub_quantity"),
        name: "sub_quantity",
        hasSort: true,
        sortName: "sub_quantity",
        key: "sub_quantity",
        showModes: ["view0"],
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "id_identity_create",
        hasSort: true,
        sortName: "id_identity_create",
        key: "identity_create_name",
      },
      {
        type: "select-supplier",
        label: this.$t("labels.supplier"),
        placeholder: this.$t("labels.supplier"),
        name: "id_supplier",
        hasSort: true,
        sortName: "supplier_name",
        key: "supplier_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        name: "note",
        hasSort: true,
        sortName: "note",
        key: "note",
      },
    ];
    this.columns = [...columns];
  },
};
</script>

<style scoped></style>
